import { Fragment } from 'react';
import { Button } from './utils/buttons';
import { FAIcon } from './utils/FAIcons';
import { FAIconProps } from './utils/FAIcons/FAIconsProps';
import { ReactElement } from 'react';

interface NoDataDisplayProps {
	icon?: string;
	iconPrefix?: FAIconProps['prefix'];
	iconColor?: string;
	header?: string | ReactElement;
	subheader?: string;
	actionButtonText?: string;
	actionButtonLink?: string | { pathname: string; query: Record<string, any> };
	actionButtonOnClick?: () => void;
	actionButton?: boolean;
	srText?: string;
}

export const NoDataDisplay = ({
	icon,
	iconPrefix = 'fad',
	iconColor = 'text-gray-500',
	header,
	subheader,
	actionButtonText,
	actionButtonLink,
	actionButtonOnClick,
	actionButton = true,
	srText,
}: NoDataDisplayProps) => {
	return (
		<Fragment>
			<div className="w-full h-full flex flex-col space-y-4 justify-center items-center text-gray-500">
				<h1 className="text-center sr-only">{srText ? srText : 'No data.'}</h1>
				<FAIcon prefix={iconPrefix} icon={icon ? icon : 'hexagon-exclamation'} className={`text-6xl ${iconColor}`} />
				<div className="flex flex-col justify-center items-center select-none">
					<span className="text-2xl font-bold">{header ? header : 'Nothing Here!'}</span>
					<span className="font-medium text-center">{subheader ? subheader : ''}</span>
				</div>
				{actionButton ? (
					<Button link={actionButtonLink ? actionButtonLink : undefined} onClick={actionButtonOnClick}>
						{actionButtonText ? actionButtonText : 'Go Home'}
					</Button>
				) : null}
			</div>
		</Fragment>
	);
};
