import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { AppType, NextWebVitalsMetric } from 'next/dist/shared/lib/utils';
import { ReactElement, ReactNode } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import ErrorBoundary from '~/components/error-handling/ErrorBoundary';
import { Toaster } from '~/components/utils/Toaster';
import { DefaultLayout } from '~/layout/DefaultLayout';

import { trpc } from '@utils/trpc';

//to remove default margins and paddings
import '../../styles/globals.css';
import { FeatureFlag } from '@utils/featureFlags';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
datadogLogs.init({
	clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN!,
	site: process.env.NEXT_PUBLIC_DATADOG_SITE,
	forwardErrorsToLogs: true,
	sampleRate: 100,
	silentMultipleInit: true,
});
if (process.env.NEXT_PUBLIC_RUM_ENABLED === 'true') {
	datadogRum.init({
		applicationId: process.env.NEXT_PUBLIC_RUM_DATADOG_APPLICATION_ID!,
		clientToken: process.env.NEXT_PUBLIC_RUM_DATADOG_CLIENT_TOKEN!,
		site: process.env.NEXT_PUBLIC_DATADOG_SITE!,
		service: process.env.NEXT_PUBLIC_RUM_DATADOG_SERVICE!,

		// Specify a version number to identify the deployed version of your application in Datadog
		// version: '1.0.0',
		sampleRate: 100,
		sessionReplaySampleRate: 100,
		trackInteractions: true,
		trackFrustrations: true,
		trackResources: true,
		trackLongTasks: true,
		trackUserInteractions: true,
		defaultPrivacyLevel: 'allow',
	});

	datadogRum.startSessionReplayRecording();
}
/* eslint-enable @typescript-eslint/no-non-null-assertion */

export function reportWebVitals(metric: NextWebVitalsMetric) {
	datadogLogs.logger.info('next web vitals', metric);
}

export type NextPageWithLayout<TProps = Record<string, unknown>, TInitialProps = TProps> = NextPage<TProps, TInitialProps> & {
	getLayout?: (page: ReactElement, props: TProps) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

const GlazierApp = (({ Component, pageProps }: AppPropsWithLayout) => {
	const getLayout = Component.getLayout ?? ((page) => <DefaultLayout featureFlags={[] as FeatureFlag[]}>{page}</DefaultLayout>);

	return getLayout(
		<ErrorBoundary>
			<Component {...pageProps} />
			<Toaster />
		</ErrorBoundary>,
		pageProps
	);
}) as AppType;

export default trpc.withTRPC(GlazierApp);
