import { Address, ScheduledJobType } from '@prisma/client';

export const classNames = (...classes: (string | boolean | undefined | null)[]) => {
	return classes.filter((val) => !!val).join(' ');
};

export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatAddress = ({ address, includeNewline = true }: { address: Address; includeNewline?: boolean }) => {
	const { street1, street2, city, state, zip } = address;
	return `${street1}, ${street2 ? `${street2}, ` : ''}${includeNewline ? `\n` : ``}${city}, ${state} ${zip}`;
};

export const capitalizeEveryWord = ({ text, delimiters = [' ', '-'] }: { text: string; delimiters?: string[] }) => {
	let tmpText = text.toLowerCase();

	delimiters.forEach((d) => {
		tmpText = tmpText.split(d).map(capitalizeFirstLetter).join(d);
	});

	return tmpText;
};

export function formatPhoneNumber(phoneNumberString: string | null | undefined) {
	const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	const match = cleaned.match(/^\d?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		return '(' + match[1] + ') ' + match[2] + '-' + match[3];
	} else {
		return null;
	}
}

export function formatDollarAmount(amount: string | number) {
	let amountFormatted;

	if (typeof amount === 'string') {
		amountFormatted = parseFloat(amount);
	} else {
		amountFormatted = amount;
	}

	return '$' + amountFormatted.toFixed(2);
}

interface ITimestampedDBObj {
	updatedAt?: Date | null;
	createdAt: Date;
}

export function formatDBObjectDateTime(obj: ITimestampedDBObj, year = false, monthType: 'short' | 'long' | 'numeric' = 'long') {
	return obj.updatedAt
		? `${formatDate(obj.updatedAt, year, monthType)}, ${formatTime(obj.updatedAt)}`
		: `${formatDate(obj.createdAt, year, monthType)}, ${formatTime(obj.createdAt)}`;
}

export function formatDate(date: string | Date, year = true, monthType: 'short' | 'long' | 'numeric' = 'long') {
	const dateObj = typeof date == 'string' ? new Date(date) : date;
	if (!year) {
		return dateObj.toLocaleDateString('en-US', { month: monthType, day: 'numeric' });
	} else {
		return dateObj.toLocaleDateString('en-US', { month: monthType, day: 'numeric', year: 'numeric' });
	}
}

export const formatTimesToDatetime = (date: Date, time: string) => {
	const timeArr = time.split(':');
	if (!timeArr[0] || !timeArr[1]) {
		return undefined;
	}
	const dateObj = new Date(date);
	dateObj.setHours(parseInt(timeArr[0]) % 24);
	dateObj.setMinutes(parseInt(timeArr[1]));
	return dateObj;
};

export function getMonth(date: string, short = false) {
	if (short) {
		return new Date(date).toLocaleDateString('en-US', { month: 'short' });
	} else {
		return new Date(date).toLocaleDateString('en-US', { month: 'long' });
	}
}

export function getDayName(date: string, short = false) {
	if (short) {
		return new Date(date).toLocaleDateString('en-US', { weekday: 'short' });
	} else {
		return new Date(date).toLocaleDateString('en-US', { weekday: 'long' });
	}
}

export function getDay(date: string) {
	return new Date(date).toLocaleDateString('en-US', { day: 'numeric' });
}

export function getYear(date: string, short = false) {
	if (short) {
		return new Date(date).toLocaleDateString('en-US', { year: '2-digit' });
	} else {
		return new Date(date).toLocaleDateString('en-US', { year: 'numeric' });
	}
}

export function formatTime(date: string | Date, hour12 = true, roundQuarterHour = false) {
	const dateObj = new Date(date);

	if (roundQuarterHour) {
		return roundToQuarterHour(dateObj).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12 });
	} else {
		return dateObj.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12 });
	}
}

export function roundToQuarterHour(date: Date) {
	const currentMinutes = date.getMinutes();
	const minutesToNextQuarterHour = 15 - (currentMinutes % 15);
	const minutesToLastQuarterHour = currentMinutes % 15;

	if (minutesToLastQuarterHour < minutesToNextQuarterHour) {
		date.setMinutes(currentMinutes - minutesToLastQuarterHour);
		return date;
	} else {
		date.setMinutes(currentMinutes + minutesToNextQuarterHour);
		return date;
	}
}

export function getDifferenceBetweenDateAndNow(date: Date) {
	// The time for many of the values in the database is off by the UTC offset, so for now we need to adjust the time accordingly.
	const offsetDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

	return Date.now() - offsetDate.getTime();
}

export function getTimeDifferenceShort(date?: Date) {
	if (!date) return undefined;

	const timeDifference = getDifferenceBetweenDateAndNow(date);
	const seconds = Math.floor(timeDifference / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);
	const weeks = Math.floor(days / 7);
	const months = Math.floor(weeks / 4.35);
	const years = Math.floor(months / 12);

	let timeAgo = 'Just now';

	if (years > 0) {
		timeAgo = `${years}y ago`;
	} else if (months > 0) {
		timeAgo = `${months}m ago`;
	} else if (weeks > 0) {
		return (timeAgo = `${weeks}w ago`);
	} else if (days > 0) {
		return (timeAgo = `${days}d ago`);
	} else if (hours > 0) {
		return (timeAgo = `${hours}h ago`);
	} else if (minutes > 0) {
		return (timeAgo = `${minutes}m ago`);
	} else if (seconds > 15) {
		return (timeAgo = `${seconds}s ago`);
	} else {
		return timeAgo;
	}
}

export function googleURLFormatter(address: Address, useCoords = false) {
	if (useCoords && address.latitude && address.longitude) {
		return `https://maps.google.com/?q=${address.latitude},${address.longitude}`;
	} else {
		//Throw it to google to do the right thing if we don't have a map location
		return `https://maps.google.com/?q=${formatAddress({ address })}`;
	}
}

export function formatStageName(stage: string) {
	return capitalizeEveryWord({ text: stage.split('_').join(' ') });
}

export function getFullName<T extends { firstName: string; lastName: string }>(user: T) {
	return `${user.firstName} ${user.lastName}`;
}

export function getTimeDifference(time: string) {
	const timePosted = new Date(time);
	const now = new Date();
	const timeDiff = now.getTime() - timePosted.getTime();
	const seconds = Math.floor(timeDiff / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);
	const weeks = Math.floor(days / 7);
	const months = Math.floor(weeks / 4.35);
	const years = Math.floor(months / 12);

	let timeAgo = 'Just now';

	if (years > 0) {
		timeAgo = `${years} year${years > 1 ? 's' : ''} ago`;
	} else if (months > 0) {
		timeAgo = `${months} month${months > 1 ? 's' : ''} ago`;
	} else if (weeks > 0) {
		return (timeAgo = `${weeks} week${weeks > 1 ? 's' : ''} ago`);
	} else if (days > 0) {
		return (timeAgo = `${days} day${days > 1 ? 's' : ''} ago`);
	} else if (hours > 0) {
		return (timeAgo = `${hours} hour${hours > 1 ? 's' : ''} ago`);
	} else if (minutes > 0) {
		return (timeAgo = `${minutes} minute${minutes > 1 ? 's' : ''} ago`);
	} else if (seconds > 15) {
		return (timeAgo = `${seconds} seconds ago`);
	} else {
		return timeAgo;
	}
}

type IconFromSJType<T extends { jobType: ScheduledJobType }> = T;

export const iconFromScheduledJob = <T extends { jobType: ScheduledJobType }>(scheduledJob?: IconFromSJType<T>) => {
	if (!scheduledJob?.jobType) return 'calendar';
	switch (scheduledJob.jobType) {
		case ScheduledJobType.FABRICATION:
			return 'wrench';
		case ScheduledJobType.INSTALL:
			return 'window-frame';
		case ScheduledJobType.MEASURE:
			return 'ruler-combined';
		case ScheduledJobType.OTHER:
			return 'calendar-pen';
	}
};
