import { JobPhase, Role } from '@prisma/client';
import { CustomJobPhase } from '@services/context.service';
import { FeatureFlag, isFeatureEnabled } from '@utils/featureFlags';
import { titlifyEnum } from '@utils/stringHelpers';
import { ReactNode } from 'react';
import { useFeatureFlagContext } from '~/react_context/FeatureFlagContext';
import { useUserContext } from '~/react_context/UserContext';
import { JobSubView } from '~/server/schema/job.schema';

export const useSideNavItemsForRole = (role: Role | null) => {
	const { taskCount, taskCountWithEmailDeliveryFailed, businessCategorySummary, quickEstimatesCount } = useUserContext();

	function getBusinessCategoryIcon(key: CustomJobPhase, subView: JobSubView) {
		return Object.entries(businessCategorySummary[key]).map(([key, value]) => {
			return {
				name: titlifyEnum(key),
				icon: value.icon,
				href: `/jobs/category/${key.toLowerCase()}?subView=${subView}`,
				notification: value.jobCount,
			};
		});
	}

	function sumNotificationByCategory(key: CustomJobPhase) {
		return Object.entries(businessCategorySummary[key]).reduce((acc, [_, value]) => {
			return acc + value.jobCount;
		}, 0);
	}

	switch (role) {
		case Role.FULL:
			return {
				navigation: [
					{ name: 'Dashboard', icon: 'gauge-circle-bolt', href: '/' },
					businessCategorySummary[JobPhase.ESTIMATE]
						? {
								name: 'My Bids',
								icon: 'bullseye-arrow',
								href: `/jobs?subView=${JobSubView.ALL_OPPORTUNITIES}`,
								notification: sumNotificationByCategory(JobPhase.ESTIMATE),
								subItems: getBusinessCategoryIcon(JobPhase.ESTIMATE, JobSubView.ALL_OPPORTUNITIES),
							}
						: { name: 'Jobs', icon: 'bullseye-arrow', href: '/jobs' },
					businessCategorySummary[JobPhase.WORK_ORDER]
						? {
								name: 'Won Jobs',
								icon: 'briefcase',
								href: `/jobs?subView=${JobSubView.ALL_WON_JOBS}`,
								notification: sumNotificationByCategory(JobPhase.WORK_ORDER),

								subItems: getBusinessCategoryIcon(JobPhase.WORK_ORDER, JobSubView.ALL_WON_JOBS),
							}
						: { name: 'Won Jobs', icon: 'briefcase', href: `/jobs?subView=${JobSubView.ALL_WON_JOBS}` },
					businessCategorySummary[JobPhase.JOB_LOST]
						? {
								name: 'Closed',
								icon: 'lock',
								href: `/jobs?subView=${JobSubView.CLOSED}`,
								notification: sumNotificationByCategory(JobPhase.JOB_LOST),
								subItems: getBusinessCategoryIcon(JobPhase.JOB_LOST, JobSubView.CLOSED),
							}
						: { name: 'Closed', icon: 'lock', href: `/jobs?subView=${JobSubView.CLOSED}` },
					businessCategorySummary['FLAGGED']
						? {
								name: 'Flagged',
								icon: 'flag-checkered',
								href: `/jobs?subView=${JobSubView.FLAGGED}`,
								notification: sumNotificationByCategory('FLAGGED'),
								subItems: getBusinessCategoryIcon('FLAGGED', JobSubView.FLAGGED),
							}
						: { name: 'Flagged', icon: 'flag-checkered', href: `/jobs?subView=${JobSubView.FLAGGED}` },

					{ name: 'Quick Estimates', icon: 'file-pen', href: '/quick-estimate', notification: quickEstimatesCount },
					{
						name: 'Inbox',
						icon: 'bell',
						href: '/tasks',
						notification: taskCount,
						rightIcon: taskCountWithEmailDeliveryFailed ? 'exclamation-triangle' : '',
					},
					{ name: 'Customers', icon: 'address-book', href: '/customers' },
					{ name: 'Time Tracking', icon: 'clock-three', href: '/time-tracking' },
					{ name: 'Scheduling', icon: 'calendar-day', href: '/schedule?view=Daily' },
					// { name: 'Team', icon: 'people-group', href: '#' },
					{ name: 'Purchase Orders', icon: 'cart-shopping', href: '/purchase-orders' },
					{ name: 'Suppliers & Materials', icon: 'screwdriver-wrench', href: '/materials' },
					{ name: 'Transactions', icon: 'money-check-dollar', href: '/transactions' },
					// TODO this is disabled until the profit and loss page is ready
					// { name: 'Profit & Loss', icon: 'chart-mixed-up-circle-dollar', href: '/profit-and-loss' },
				],
				bottomNav: [
					{ name: 'Training Videos', icon: 'film', href: '/training-videos' },
					{ name: 'Settings', icon: 'gears', href: '/settings' },
				],
			};
		case Role.TECHNICIAN:
			return {
				navigation: [
					businessCategorySummary[JobPhase.ESTIMATE]
						? {
								name: 'My Bids',
								icon: 'bullseye-arrow',
								href: `/jobs?subView=${JobSubView.ALL_OPPORTUNITIES}`,
								subItems: getBusinessCategoryIcon(JobPhase.ESTIMATE, JobSubView.ALL_OPPORTUNITIES),
							}
						: { name: 'Jobs', icon: 'bullseye-arrow', href: '/jobs' },
					businessCategorySummary[JobPhase.WORK_ORDER]
						? {
								name: 'Won Jobs',
								icon: 'briefcase',
								href: `/jobs?subView=${JobSubView.ALL_WON_JOBS}`,
								subItems: getBusinessCategoryIcon(JobPhase.WORK_ORDER, JobSubView.ALL_WON_JOBS),
							}
						: { name: 'Won Jobs', icon: 'briefcase', href: `/jobs?subView=${JobSubView.ALL_WON_JOBS}` },
					businessCategorySummary[JobPhase.JOB_LOST]
						? {
								name: 'Closed',
								icon: 'lock',
								href: `/jobs?subView=${JobSubView.CLOSED}`,
								subItems: getBusinessCategoryIcon(JobPhase.JOB_LOST, JobSubView.CLOSED),
							}
						: { name: 'Closed', icon: 'lock', href: `/jobs?subView=${JobSubView.CLOSED}` },
					businessCategorySummary['FLAGGED']
						? {
								name: 'Flagged',
								icon: 'flag-checkered',
								href: `/jobs?subView=${JobSubView.FLAGGED}`,
								subItems: getBusinessCategoryIcon('FLAGGED', JobSubView.FLAGGED),
							}
						: { name: 'Flagged', icon: 'flag-checkered', href: `/jobs?subView=${JobSubView.FLAGGED}` },

					{ name: 'Inbox', icon: 'bell', href: '/tasks' },
					{ name: 'Scheduling', icon: 'calendar-day', href: '/schedule?view=Daily' },
					{ name: 'Suppliers & Materials', icon: 'screwdriver-wrench', href: '/materials' },
				],
				bottomNav: [{ name: 'Settings', icon: 'gears', href: '/user-settings' }],
			};
		default:
			return {
				navigation: [],
			};
	}
};

export const useJobNavItemsForRole = (role: Role | null, jobRoot: string, isCommercial: boolean, numberOfEstimates: number) => {
	const context = useFeatureFlagContext();

	switch (role) {
		case Role.FULL:
			const navItems: { id: string; href: string; compareHref?: string; name: ReactNode; icon: string }[] = [
				{ id: 'job-nav-overview', name: 'Overview', href: jobRoot, icon: 'gauge-circle-bolt' },
				{ id: 'job-nav-attachments', name: 'Attachments', href: `${jobRoot}/attachments?view=Grid`, icon: 'file-image' },
				{
					id: 'job-nav-estimates',
					name: 'Estimates',
					href: numberOfEstimates === 1 ? `${jobRoot}/estimates/latest` : `${jobRoot}/estimates`,
					compareHref: `${jobRoot}/estimates`,
					icon: 'file-signature',
				},
				{ id: 'job-nav-change-orders', name: 'Change Orders', href: `${jobRoot}/change-orders`, icon: 'exchange' },
				{
					id: 'job-nav-glass-builder',
					name: (
						<>
							Glass Builder&nbsp;&nbsp;<span className="bg-yellow-100 text-yellow-800 px-1 rounded-md text-xs">Beta</span>
						</>
					),
					href: `${jobRoot}/glass-builder`,
					icon: 'tools',
				},
				{ id: 'job-nav-work-orders', name: 'Work Orders', href: `${jobRoot}/work-orders`, icon: 'gauge-circle-bolt' },
				{ id: 'job-nav-measures-and-installs', name: 'Measures & Installs', href: `${jobRoot}/site-photos`, icon: 'ruler-combined' },
				{ id: 'job-nav-daily-reports', name: 'Daily Reports', href: `${jobRoot}/daily-reports`, icon: 'file-chart-column' },
				{ id: 'job-nav-quote-requests', name: 'Quote Requests', href: `${jobRoot}/quote-requests`, icon: 'comments' },
				{ id: 'job-nav-purchase-orders', name: 'Purchase Orders', href: `${jobRoot}/purchase-orders`, icon: 'shopping-cart' },
				{ id: 'job-nav-phases', name: 'Phases', href: `${jobRoot}/phases`, icon: 'tasks' },
				{ id: 'job-nav-key-dates', name: 'Key Dates', href: `${jobRoot}/key-dates/edit`, icon: 'gauge-circle-bolt' },
				{ id: 'job-nav-payments', name: 'Payments', href: `${jobRoot}/payments`, icon: 'sack-dollar' },
				{ id: 'job-nav-job-costing', name: 'Job Costing', href: `${jobRoot}/job-costing`, icon: 'chart-mixed' },
			];
			if (isCommercial) {
				navItems.push({ id: 'job-nav-commercial-billing', name: 'Billing', href: `${jobRoot}/commercial-billing`, icon: 'briefcase' });
			}

			return navItems;
		case Role.TECHNICIAN:
			return [
				{ id: 'job-nav-overview', name: 'Overview', href: jobRoot },
				{ id: 'job-nav-attachments', name: 'Attachments', href: `${jobRoot}/attachments?view=Grid` },
				{ id: 'job-nav-measures-and-installs', name: 'Measures & Installs', href: `${jobRoot}/site-photos` },
				{ id: 'job-nav-work-orders', name: 'Work Orders', href: `${jobRoot}/work-orders` },
			];
		default:
			return [];
	}
};
