import React, { useEffect, useState } from 'react';
import { capitalizeEveryWord, classNames } from '~/components/utils';
import { ButtonGroupProps } from '~/components/utils/UtilityProps';

export default function ButtonGroup({
	id,
	buttons,
	sendActiveButton,
	initActiveButton,
	fullWidth,
	narrow,
	disabled,
	buttonClasses = 'text-base',
	containerClasses,
	labelCase = 'Title',
}: ButtonGroupProps) {
	const _activeButton = initActiveButton
		? buttons.find((button) => button.label === initActiveButton) || buttons[0]
		: buttons.find((b) => b.isActive) || buttons[0];
	const [activeButton, setActiveButton] = useState(_activeButton?.label);

	//Check for changes in sent active button
	useEffect(() => {
		if (_activeButton) {
			setActiveButton(_activeButton.label);
		}
	}, [_activeButton]);

	const handleView = (buttonLabel: string) => {
		setActiveButton(buttonLabel);
		sendActiveButton(buttonLabel);
		const button = buttons.find((button) => button.label === buttonLabel);
		if (button && button.onClick) {
			button.onClick();
		}
	};

	const setLabelCase = (buttonLabel: string) => {
		switch (labelCase) {
			case 'Title':
				return capitalizeEveryWord({ text: buttonLabel });
			case 'Raw':
				return buttonLabel;
			case 'Uppercase':
				return buttonLabel.toUpperCase();
			default:
				return capitalizeEveryWord({ text: buttonLabel });
		}
	};

	return (
		<div
			id={id}
			className={classNames(
				fullWidth ? 'w-full justify-center' : '',
				disabled ? 'bg-gray-50 ' : 'bg-gray-200',
				`relative z-0 p-1 inline-flex space-x-1 border-2 border-gray-300 rounded-lg shadow-inner ${containerClasses}`
			)}
		>
			{buttons.map((button, index) => (
				<button
					id={button.id}
					key={index}
					type="button"
					className={
						//check index to round corners for edge buttons
						classNames(
							// index === 0 ? 'rounded-l-md' : index === buttons.length - 1 ? 'rounded-r-md' : '',

							//check if button is active
							classNames(
								activeButton === button.label
									? 'bg-white text-primary-dark font-bold disabled:bg-gray-200 disabled:cursor-not-allowed'
									: 'text-secondary font-medium hover:bg-white disabled:hover:bg-gray-50 disabled:hover:cursor-not-allowed',
								narrow ? '' : 'py-0.5',
								fullWidth ? 'w-full justify-center' : ''
							),
							`relative inline-flex items-center rounded-md px-3 pointer-events-auto ${buttonClasses}`
						)
					}
					onClick={() => handleView(button.label)}
					disabled={disabled}
				>
					{button.icon ? button.icon : null}
					{setLabelCase(button.label)}
				</button>
			))}
		</div>
	);
}
