import { datadogLogs } from '@datadog/browser-logs';
import { Address, Contact, FileType, PricingType, ScheduledJobType } from '@prisma/client';
import { CustomerFull } from '@services/customer.service';
import { capitalizeFirstLetter } from '~/components/utils';
import { FieldJobCard, FieldJobType } from '~/server/schema/field.schema';

export const titlifyEnum = (val: string) => {
	const spacedOut = val.replace(/_/g, ' ').toLowerCase();
	return spacedOut.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()).trim();
};

export const allCapsTitlifyEnum = (val: string) => {
	const spacedOut = val.replace(/_/g, ' ').toUpperCase();
	return spacedOut.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()).trim();
};

// ENUM_VAL to enum-val
export const enum2htmlid = (enumVal: string) => {
	return enumVal.replace(/_/g, '-').toLowerCase();
};

export const camel2Title = (camelCaseVal: string) => {
	return camelCaseVal.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
};

export const installTimeShort = (installMinutesRequired: number) => {
	return `${Math.floor(installMinutesRequired / 60)}h:${installMinutesRequired % 60}m`;
};

// Force free text to dash-separated-format
export const text2id = (text: string) => {
	return text
		.toLowerCase() // lower case
		.replace(/[^a-zA-Z0-9 ]/g, '') // remove special characters
		.replace(/[-_:]/g, ' ') // separators to spaces
		.replace(/\W+/g, '-'); // whitespace to dashes
};

/**
 * Round a non-currency value to a set number of digits.
 *
 * @param num Number to round (Eg: 1.2345)
 * @param trailingDigits Number of digits (2)
 * @returns "1.23"
 */
export const roundToDigits = (num: number, trailingDigits: number) => {
	const factor = 10 ** trailingDigits;
	return (Math.round(num * factor) / factor).toString();
};

export const truncToDigits = (num: number, trailingDigits: number) => {
	const factor = 10 ** trailingDigits;
	return Math.trunc(num * factor) / factor;
};

export const formatDisplayPhone = (phoneNumber: string) => {
	// NOTE only formats +1 phone numbers in the North American style
	const cleaned = ('' + phoneNumber).replace(/\D/g, '');
	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		const intlCode = match[1] ? '+1 ' : '';
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}
	// if string can't be formatted, just return it unformatted to avoid blanks
	return phoneNumber;
};

export const formatUSD = (value?: number | bigint, removeChange?: boolean) => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});

	if (removeChange) {
		return formatter.format(value ? value : 0).replace(/\.00$/, '');
	}
	return formatter.format(value ? value : 0);
};

export const formatNumberToPercent = (value: number) => {
	return `${Math.round(value * 100)}%`;
};

export const formatLengthInInches = (value: string) => {
	return value.endsWith('"') ? value : `${value}"`;
};

/*
Turn a search string into a regex with non-alphanumeric characters removed.
*/
export const tokenizedRegex = (searchString: string) => {
	const nontokens = /[^a-z0-9]+/gi;
	const tokens = searchString.split(nontokens);
	return RegExp(tokens.join('.*'), 'i');
};

export const highlightStringMatches = (text: string, query?: string) => {
	if (!query) return text;
	const newQuery = query.replace('+', '\\+');
	const regex = new RegExp(`(${newQuery})`, 'gi');
	const parts = text.split(regex);

	return parts
		.filter((part) => part)
		.map((part, index) =>
			regex.test(part) ? (
				<span key={index} className="bg-cyan-200 font-bold">
					{part}
				</span>
			) : (
				<span key={index}>{part}</span>
			)
		);
};

export function convertToSize(bytes: number | null) {
	if (bytes === 0 || bytes === null) return '0';
	const k = 1024,
		sizes = ['', 'KB', 'MB', 'GB', 'TB'], //don't thing we'll ever get to TB, but just in case
		i = Math.floor(Math.log(bytes) / Math.log(k));
	return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
}

export function generateAddressString(address: Address, separator = '\n', name?: string): string {
	if (!address) {
		datadogLogs.logger.error('Undefined/null/falsy address being passed to PDF address generator.');
		return '';
	}

	return `${name ? `${name}${separator}` : ''}${address.street1 ? address.street1 : ''}${address.street2 ? `${separator}${address.street2}` : ''}${separator}${
		address.city ? address.city : ''
	}, ${address.state ? address.state : ''}, ${address.zip ? address.zip : ''}`;
}

// Necessary because some addresses in db don't have city, state, or zip
export function isDisplayableAddress(address?: Address | null): address is Address {
	if (!address) {
		return false;
	}

	return !!(address.street1 && address.city && address.state && address.zip);
}

export function generateCustomerContactString(customer: CustomerFull): string {
	const customerDefaultContact = customer.contacts?.find((c) => c.isDefault);
	return `${customer.isCommercial ? `${customer.name}` : `${customerDefaultContact?.name}`}${
		customerDefaultContact?.phone ? `\n${formatDisplayPhone(customerDefaultContact.phone)}` : ''
	}${customerDefaultContact?.email ? `\n${customerDefaultContact.email}` : ''}${
		isDisplayableAddress(customer.billingAddress) ? `\n\n${generateAddressString(customer.billingAddress)}` : ''
	}`;
}

export function generateContactString(contacts: Contact | Contact[]): string {
	if (Array.isArray(contacts)) {
		return contacts
			.map(
				(contact) =>
					`${contact?.name ? `${contact.name}` : ''}${contact?.phone ? `\n${formatDisplayPhone(contact.phone)}` : ''}${
						contact?.email ? `\n${contact.email}` : ''
					}`
			)
			.join('\n\n');
	} else {
		const contact = contacts as Contact;
		return `${contact?.name ? `${contact.name}` : ''}${contact?.phone ? `\n${formatDisplayPhone(contact.phone)}` : ''}${
			contact?.email ? `\n${contact.email}` : ''
		}`;
	}
}

export function toCamelCase(str: string) {
	// If the string is all uppercase
	if (str === str.toUpperCase()) {
		return str.toLowerCase();
	}

	// If the string contains underscores
	return str
		.toLowerCase()
		.split('_')
		.map((word, index) => (index === 0 ? word : word[0] ? word[0].toUpperCase() + word.slice(1) : ''))
		.join('');
}

export function convertMillisecondsToTime(ms: number) {
	const seconds = Math.floor(ms / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);

	if (hours > 0) {
		return `${hours} hour${hours > 1 ? 's' : ''}`;
	} else if (minutes > 0) {
		return `${minutes} minute${minutes > 1 ? 's' : ''}`;
	} else {
		return 'Less than a minute';
	}
}

export function kebabToTitleCase(kebabStr: string): string {
	return kebabStr
		.split('-') // split the string into words
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize the first character of each word
		.join(' '); // join the words back into a string, separated by spaces
}

export function toTitleCase(string: string) {
	return string.replace(/\w\S*/g, function (text) {
		return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
	});
}

export function readFileExtension(fileName: string) {
	if (!fileName.includes('.') || fileName === '.') {
		return '';
	}

	// remove any and all trailing periods
	while (fileName.endsWith('.')) {
		fileName = fileName.slice(0, -1);
	}

	const parts = fileName.split('.');

	return parts.pop();
}

export function fileExtensionToFileType(extension: string): FileType {
	const possibleFileTypes: string[] = Object.values(FileType);

	const upcasedExtension = extension.toUpperCase();

	if (possibleFileTypes.includes(upcasedExtension)) {
		return upcasedExtension as FileType;
	} else {
		return FileType.OTHER;
	}
}

export function readFileTypeFromExtension(fileName: string): FileType {
	const extension = readFileExtension(fileName);
	return extension ? fileExtensionToFileType(extension) : FileType.OTHER;
}

export function priceTypeLabel(pt: PricingType) {
	switch (pt) {
		case 'SQUAREFOOT':
			return 'per sqft';
		case 'EACH':
			return 'each';
		case 'HOURLY':
			return 'per hour';
		case 'LENGTH':
			return 'per linear foot';
		case 'LINEAR_INCH':
			return 'per united foot';
	}
	return '';
}

export function formatFileName(fileName: string): string {
	const hyphenatedFileName = fileName.replace(/\s+/gi, '-'); // replace spaces with hyphens

	// remove special characters S3 hates
	const sanitizedFileName = hyphenatedFileName.replace(/[^\w.-]/g, '');

	// encode any stragglers
	return encodeURIComponent(sanitizedFileName);
}

export function getTaskButtonLabel(jobType: FieldJobType | ScheduledJobType): string {
	if (typeof jobType === 'string') {
		switch (jobType) {
			case ScheduledJobType.MEASURE:
				return 'Measurement';
			case ScheduledJobType.FABRICATION:
				return 'Fabricate';
			case ScheduledJobType.INSTALL:
				return 'Install';
			default:
				return capitalizeFirstLetter(jobType.toLowerCase());
		}
	} else {
		switch (jobType) {
			case FieldJobType.MEASUREMENT:
				return 'Take Measurement';
			case FieldJobType.PURCHASE_ORDER:
				return 'Inspect PO';
			case FieldJobType.FABRICATION:
				return 'Fabricate';
			case FieldJobType.INSTALL:
				return 'Install';
			default:
				return `Start ${jobType}` || 'Start';
		}
	}
}
